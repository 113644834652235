<template>
  <v-dialog
    v-model="dialog"
    persistent
    max-width="1000px"
    origin="left top"
    scrollable
  >
    <v-card>
      <v-card-title>{{ title }}</v-card-title>
      <v-card-text class="mb-6">
        <v-form ref="form" v-model="valid" lazy-validation>
          <h3 class="mt-4 mb-2">Informações</h3>
          <v-row class="d-flex justify-end">
            <v-col cols="2">
              <app-code-field
                label="Código"
                v-model="form.code"
                :clear-on-auto-code="isInsert"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="6">
              <app-cnpj-field v-model="form.cnpj" label="CNPJ" />
            </v-col>
          </v-row>

          <v-row>
            <v-col md="6">
              <app-text-field v-model="form.name" :label="name_label" />
            </v-col>
            <v-col md="6">
              <app-text-field v-model="form.alias" :label="alias_label" />
            </v-col>
          </v-row>
          <v-row>
            <v-col md="4">
              <app-text-field
                :disabled="form.inscricao_estadual_isento == 1"
                v-model="form.inscricao_estadual"
                label="Inscrição Estadual"
              />
            </v-col>
            <v-col md="2">
              <v-checkbox
                class="mt-0"
                v-model="form.inscricao_estadual_isento"
                label="Isento"
                hide-details="auto"
                :true-value="1"
                :false-value="0"
              />
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-switch
                class="mt-0"
                v-model="form.can_process_deliveries"
                :true-value="1"
                :false-value="0"
                label="Pode processar entregas"
                :hide-details="true"
              />
              <v-switch
                v-model="form.status"
                :true-value="1"
                :false-value="0"
                label="Ativo"
                :hide-details="true"
              />
            </v-col>
          </v-row>

          <h3 class="mt-10 mb-6">Telefones</h3>
          <PhoneContactsForm v-model="form.phones" />

          <h3 class="mt-10 mb-6">Endereço</h3>
          <AddressForm v-model="form.main_address" />

          <h3 class="mt-10 mb-4">Informações no Aplicativo</h3>
          <v-row class="d-flex align-center">
            <v-col cols="7">
              <app-text-field
                label="Nome no Aplicativo"
                v-model="form.app_name"
              />
            </v-col>
            <v-col class="d-flex justify-space-around">
              <v-switch
                v-model="form.is_store"
                :true-value="1"
                :false-value="0"
                label="É Loja"
              />
              <v-switch
                v-model="form.app_status"
                true-value="1"
                false-value="0"
                label="Exibir no aplicativo"
              />
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>

      <v-card-actions
        class="justify-end py-4"
        style="background-color: #f5f5f5"
      >
        <v-btn
          @click="dialog = false"
          text
          class="text-capitalize"
          color="secondary"
          >Cancelar</v-btn
        >
        <v-btn @click="handleSave()" class="text-capitalize" color="primary"
          >Salvar</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import PhoneContactsForm from "@/components/app/forms/PhoneContactsForm.vue";
import EmailContactsForm from "@/components/app/forms/EmailContactsForm.vue";
import SiteContactsForm from "@/components/app/forms/SiteContactsForm.vue";
import CommentList from "@/components/comments/CommentList.vue";
import AddressForm from "@/components/app/forms/AddressForm.vue";

export default {
  components: {
    PhoneContactsForm,
    EmailContactsForm,
    SiteContactsForm,
    CommentList,
    AddressForm,
  },

  data() {
    return {
      dialog: false,

      statusText: {
        1: " Ativo",
        0: " Inativo",
      },
      appStatusText: {
        1: "Visivel no aplicativo",
        0: "Oculto no aplicativo",
      },
      isStoreText: {
        1: "Loja",
        0: "Não é loja",
      },
      rules: {
        required: (value) => !!value || "Required.",
      },
      title: "Cadastrar Empresa",

      valid: false,
      form: {},
      baseForm: {
        code: null,
        name: null,
        short_name: null,
        alias: null,
        cnpj: null,
        inscricao_estadual: null,
        inscricao_estadual_isento: null,
        is_store: "0",
        app_name: null,
        app_status: "0",
        status: "1",
        main_address: {
          type: "mainAddress",
          address: null,
          number: null,
          complement: null,
          district: null,
          postal_code: null,
          city_district: null,
          city: null,
          state: null,
          country: null,
        },
        phones: [],
        emails: [],
        sites: [],
      },
    };
  },

  computed: {
    IsJuridic() {
      return this.form.person_type == "juridica";
    },
    alias_label() {
      if (this.IsJuridic) {
        return "Nome Fantasia";
      } else {
        return "Apelido";
      }
    },

    name_label() {
      if (this.IsJuridic) {
        return "Razão Social";
      } else {
        return "Nome";
      }
    },
    isInsert() {
      return !this.form.id;
    },
  },

  created() {
    this.reset();
  },

  methods: {
    async open(id) {
      this.reset();

      if (id) {
        await this.showCompany(id);
      }

      this.setDefaults();

      this.dialog = true;
    },

    reset() {
      this.form = JSON.parse(JSON.stringify(this.baseForm));
      this.title = "Cadastrar Empresa";
    },

    setDefaults() {
      this.form.phones.push({
        contact: null,
      });

      this.form.emails.push({
        contact: null,
      });

      if (this.form.sites.length == 0) {
        this.form.sites.push({
          contact: null,
        });
      }
    },

    async showCompany(id) {
      this.title = "Atualizar Empresa";

      this.$store.commit("app/showLoading");

      await this.$http.$get("company/company/" + id).then((response) => {
        this.setCompany(response.company);
        this.$store.commit("app/hideLoading");
      });
    },

    setCompany(company) {
      if (company.main_address == null) {
        company.main_address = JSON.parse(
          JSON.stringify(this.baseForm.main_address)
        );
      }
      
      this.form = company;
    },

    handleSave() {
      this.$refs.form.validate();
      if (this.valid == true) {
        if (this.isInsert) {
          this.store();
        } else {
          this.update();
        }
      }
    },

    store() {
      this.$http.store("company/company", this.form).then((response) => {
        this.processSaved();
      });
    },

    update() {
      this.$http
        .update("company/company", this.form.id, this.form)
        .then((response) => {
          this.processSaved();
        });
    },

    processSaved() {
      this.$emit("store");
      this.dialog = false;
    },

  },
};
</script>

<style>
</style>
s